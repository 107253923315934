html, body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.framed {
  height: 100vh!important;
  width: 100vw!important;
  min-height: 100vh!important;
  min-width: 100vw!important;
}